import request from '@http';

// 2022/08/11 王江毅 收付款日记账
export function getProceedsListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/proceeds/pay/log/page',
        params,
    });
}

// 2022/08/12 王江毅 收付款日记账
export function proceedsExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/proceeds/pay/log/export',
        responseType: 'blob',
        params,
    });
}
// 2022/08/15 王江毅 收付款日记账合计接口
export function getProceedsPayTotalApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/proceeds/pay/log/total',
        params,
    });
}